@import '../../colors.scss';

@keyframes tabActiveAnim {
    0% {
        transform: scale(1);
    }
    96% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.tab-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: $color-black;
    font-weight: 700;
    font-size: .9em;
    padding: 5px 8px;
    border-radius: 5px;
    transition: all .2s ease;
    margin: 0 5px;
    position: relative;

    .hover-line {
        position: absolute;
        bottom: -2px;
        height: 4px;
        width: 100%;
        max-width: 0;
        transition: all .2s ease;
        background-color: $color-black;
    }

    &:hover:not(.active) {
        .hover-line {
            max-width: 300px;
        }
    }

    &.active {
        background-color: $color-bg-main;
        color: $color-white;
        animation: tabActiveAnim 20s infinite linear;
    }

    img {
        height: 1.5em;
        width: auto;
        margin-right: 10px;
    }
}