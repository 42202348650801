@import '../../colors.scss';

@keyframes InfoPageAppear {
    from {
        transform: translateY(-5%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

.InfoPage {
    animation: InfoPageAppear .5s ease 1 forwards;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .main-wrapper {
        padding: 0px 20px 20px 20px;
        background-color: $color-white;
        color: $color-black;
        width: 70%;
        margin-right: 10%;
        max-width: 800px;
        font-size: 1.2em;
        box-shadow: 5px 5px 20px rgba(0,0,0,.5);
        text-align: justify;

        a {
            color: $color-black;
            text-decoration: none;
            font-weight: 700;
        }
    }
}