@import '../../colors.scss';

.Header {
    width: 100%;
    padding: 8px 5px;
    background-color: $color-white;
    color: $color-black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 1.2em;

    .title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        color: $color-black;
        font-weight: 700;
        img.logo {
            height: 2em;
            width: auto;
            margin-right: 10px;
        }
    }

    .tabs-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        transition: all .2s ease;
    }

    .menu-burger {
        display: none;
        height: 1.5em;
        width: 1.5em;
        background: transparent center center / cover;
        transition: all .2s ease;
        margin-right: 10px;
    }
}

@media (max-width: 960px) {
    .tabs-wrapper {
        width: 100%;
        overflow: hidden;
        flex-wrap: wrap;
        &:not(.visible) {
            max-height: 0px;
        }
        .tab-wrapper {
            width: 100%;
            margin-top: 10px;
            padding-left: 10px;
        }
    }
    .Header .menu-burger {
        display: block;
    }
}