@import '../../colors.scss';

@keyframes CompPageAppear {
    from {
        transform: translateY(-5%);
        opacity: 0;
    }
    to {
        transform: translateY(0%);
        opacity: 1;
    }
}

.CompPage {
    animation: CompPageAppear .5s ease 1 forwards;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .main-wrapper {
        color: $color-black;
        width: 70%;
        margin-right: 10%;
        max-width: 800px;
        font-size: 1em;
        box-shadow: 5px 5px 20px rgba(0,0,0,.5);
        display: flex;
        flex-direction: column;
        max-height: 80vh;
        overflow-y: auto;
        padding-right: 8px;

        .wrapper {
            background-color: $color-white;
            width: 100%;
            padding: 0px 20px 20px 20px;
            text-align: justify;
            
            &:not(:last-child) {
                margin: 0 0 20px 0;
            }

            h1 {
                font-size: 1.1em;
            }

            p {
                margin: 5px;
            }
        }

        a {
            color: $color-black;
            text-decoration: none;
            font-weight: 700;
        }
    }
}