@import './colors.scss';

@keyframes animateBg {
  0% { background-position: 0% 0%; }
  100% { background-position: 0% 100%; }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .App {
    width: 100vw;
    height: 100vh;
    animation: animateBg 10s linear alternate-reverse infinite;
    background-image: linear-gradient(0deg,$color-bg-main,$color-bg-sub);
    background-size: 100% 200%;
    display: flex;
    flex-direction: column;

    .background {
      position: absolute;
      bottom: 0;
      left: 5%;
      height: 45vh;
      max-height: 760px;
      width: auto;
      opacity: .7;
    }

    .page {
      flex: 1;
      width: 100%;
    }
  }
}

.spacer {
  flex: 1;
}

* {
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(0,0,0,.5);
  }

  ::-webkit-scrollbar-thumb {
    background: $color-white;
    opacity: .7;
    transition: opacity .2s ease;

    &:hover {
      opacity: 1;
    }
  }
}

@media (max-width: 960px) {
  body {
    font-size: 12px;
    .App {
      max-height: 100vh;
      overflow-y: auto;
      .page {
        align-items: flex-start;
        padding: 20px;
        .main-wrapper {
          width: 100%;
          margin: 0 0 20px 0;
          max-width: none;
          max-height: none;
          box-shadow: none;
        }
      }
    }
  }
}
